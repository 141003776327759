<template>
  <div class="subsidyApplicationDetails">

    <div class="back">
      <el-button type="text" @click="goBack"><i class="el-icon-arrow-left"></i>返回</el-button>
    </div>
    <div class="title">
      <h3>{{detailData.address}}种粮大户补贴申请表</h3>
    </div>
    <div class="time">
      申报日期:{{ detailData.declareTime }}
    </div>
    <div class="table">
      <table style="border: solid 1px #ccc">
        <tr>
          <td rowspan="5" colspan="2">基础信息</td>
          <td>姓名/单位</td>
          <td>{{detailData.applyUser}}</td>
          <td>联系电话</td>
          <td>{{detailData.phone}}</td>
        </tr>
        <tr>
          <td>身份证号/机构代码</td>
          <td colspan="4">{{detailData.idCard}}</td>
        </tr>
        <tr>
          <td>地址</td>
          <td colspan="4">{{detailData.address}}</td>
        </tr>
        <tr>
          <td rowspan="2">账户</td>
          <td>开户行</td>
          <td colspan="2">账户</td>
        </tr>
        <tr>

          <td>{{detailData.bankName}}</td>
          <td colspan="2">{{detailData.idCard}}</td>
        </tr>
      </table>

      <table>
        <tr>
          <td>承包面积(亩)</td>
          <td colspan="3">租种面积(亩)</td>
          <td>补贴面积(亩)</td>
        </tr>
        <tr>
          <td rowspan="2">{{detailData.contractArea}}</td>
          <td>合计</td>
          <td>大堰镇黑井村</td>
          <td>大堰镇桥头村</td>
          <td rowspan="2">{{detailData.totalSubsidyArea}}</td>
        </tr>
        <tr>
          <td>50</td>
          <td>25 <p style="color:#169BD5;text-decoration: underline;" @click="showDetail(scope.row)">其中平台流转(20亩)</p>
          </td>
          <td>25</td>
        </tr>
        <tr>
          <td rowspan="2">种植品种和面积(亩)</td>
          <td>合计</td>
          <td>水稻</td>
          <td>玉米</td>
          <td>小麦</td>
        </tr>
        <tr>
          <td>{{detailData.riceArea+detailData.cornArea+detailData.wheatArea}}</td>
          <td>{{detailData.riceArea}}</td>
          <td>{{detailData.cornArea}}</td>
          <td>{{detailData.wheatArea}}</td>
        </tr>
      </table>

    </div>
    <div class="introduce">
      <div class="left">
        <div class="title">
          <span> 本人承诺以上信息及提供的土地承包、租种、流转、捡种手续和面积、独立核算证明等均真实合法，如有弄虚作假，本人愿承担相关法律责任。</span>
        </div>
        <div class="acceptor">
          <div class="name">承诺人(盖章签字):</div>
          <div class="img">
            <el-image style="width: 100px; height: 100px"
              :src="baseUrl + 'admin/file/get?ossFilePath=' + detailData.sign">
            </el-image>
          </div>
        </div>
        <div class="time">
          2024年01月01日
        </div>
      </div>
      <div class="right">
        <div class="examine">
          村委会意见: <span style="font-size:18px; font-weight:bold ">审核通过</span>
        </div>
        <div class="acceptor">
          <div class="name">负责人签字:</div>
          <div class="img">
            <el-image style="width: 100px; height: 100px"
              :src="baseUrl + 'admin/file/get?ossFilePath=' + detailData.sign">
            </el-image>
          </div>
        </div>
        <div class="time">
          2024年01月01日
        </div>
      </div>
    </div>
    <div class="introduce2">
      <div class="left">
        <div class="title">
          <span> 财政所签署意见并加盖公章</span>
        </div>
        <div class="auditResult">
          审核通过
        </div>
        <div class="acceptor">
          <div class="name">承诺人(盖章签字):</div>
          <div class="img">
            <el-image style="width: 100px; height: 100px"
              :src="baseUrl + 'admin/file/get?ossFilePath=' + detailData.sign">
            </el-image>
          </div>
        </div>
        <div class="time">
          2024年01月01日
        </div>
      </div>
      <div class="right">
        <div class="title">
          农服中心签署意见并加盖公章
        </div>
        <div class="auditResult">
          <el-input type="textarea" placeholder="请输入审批意见" v-model="idea" @input="callApiOnBlur">
          </el-input>
        </div>

        <div class="acceptor">
          <div class="name">负责人签字:</div>
          <div class="img">
            <vue-qr ref="qrCode" :text="text" :logoSrc="logoSrc" :color-dark="randomColor" :callback="qrCodeCallback"
              width="110" height="110"></vue-qr>
            <p>请使用微信扫码签字</p>
          </div>
        </div>
        <div class="time">
          2024年01月01日
        </div>
      </div>


    </div>
    <div class="ImgBox">
      <div class="Left">
        <div class="title">
          <span> 法定代表人身份证照片</span>
        </div>
        <div class="filBox">
          <el-image style="width: 100px; height: 100px"
            :src="baseUrl + 'admin/file/get?ossFilePath=' + detailData.legalIdcardPicFront">
          </el-image>
          <el-image style="width: 100px; height: 100px"
            :src="baseUrl + 'admin/file/get?ossFilePath=' + detailData.legalIdcardPicBack">
          </el-image>
        </div>
      </div>
      <div class="Right">
        <div class="title">
          <span>营业执照</span>
        </div>
        <div class="filBox">
          <el-image style="width: 100px; height: 100px"
            :src="baseUrl + 'admin/file/get?ossFilePath=' + detailData.businessLicense">
          </el-image>
        </div>
      </div>
    </div>
    <div class="ImgFiles">
      <div class="title">
        承包耕地证明
      </div>
      <div class="filBox">
        <el-image style="width: 100px; height: 100px"
          v-for="(item,index) in JSON.parse(detailData.certificateContractPicUrl)" :key="index"
          :src="baseUrl + 'admin/file/get?ossFilePath=' + item.imgUrl">
        </el-image>
      </div>
    </div>

    <div class="ImgFiles">
      <div class="title">
        耕地流转合同
      </div>
      <div class="filBox">
        <el-image style="width: 100px; height: 100px"
          v-for="(item,index) in JSON.parse(detailData.landTransferContractPicUrl)" :key="index"
          :src="baseUrl + 'admin/file/get?ossFilePath=' + item.imgUrl">
        </el-image>
      </div>
    </div>

    <div class="ImgFiles">
      <div class="title">
        承包耕地证明
      </div>
      <div class="filBox">
        <el-image style="width: 100px; height: 100px" v-for="(item,index) in JSON.parse(detailData.otherFiles)"
          :key="index" :src="baseUrl + 'admin/file/get?ossFilePath=' + item.imgUrl">
        </el-image>
      </div>
    </div>


    <el-dialog title="兴村通平台社会化服务记录" :visible.sync="dialogVisible" width="70%" :before-close="handleClose">
      <table-page :data="tableData" :columns="columns" :pagination="false">
        <template slot="operation" slot-scope="scope">
          <el-button type="text" size="small" @click="showDetail(scope.row)">详情</el-button>
        </template>
      </table-page>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import VueQr from "vue-qr";
  const Url = window.globalUrl.api;
  import { grainSubsidyColumn } from '../../tableColumn'
  import { selectLargeGrainGrowersDetail, selectLandArea } from '@/api/subsidy'
  export default {
    data() {
      return {
        baseUrl: window.globalUrl.BASE_API,
        text: window.globalUrl.api,
        dialogVisible: false,
        tableData: [],
        columns: grainSubsidyColumn,
        idea: '',//审批意见
        logoSrc: "",
        taskId: "", // 查询id
        randomColor: "black",
        qrCodeUrl: "", // 存放生成的二维码url
        textarea2: '',
        detailData: [],
        isDisabled: false, // 是否禁用输入框
      }
    },
    components: {
      VueQr,
    },
    mounted() {
      this.selectLargeGrainGrowersDetail()
    },
    methods: {
      goBack() {
        this.$router.go(-1); // 返回上一级路由
      },
      // 种粮大户的详情
      async selectLargeGrainGrowersDetail() {
        this.isDisabled = this.$route.query.isDisabled
        let params = {
          declareId: this.$route.query.declareId
        }
        let res = await selectLargeGrainGrowersDetail(params)
        if (res.code == 0) {
          this.detailData = res.data
          this.taskId = res.data.taskId
          this.selectLandArea()
          this.text = `${Url}?taskId=${this.taskId}/processInstanceId=${this.detailData}/agreeReason=${this.idea}/identityId=${this.identityId}`;
        }

      },

      // 种粮大户租种面积查询
      async selectLandArea() {
        let params = {
          areaCode: this.detailData.areaCode,
        }
        let res = await selectLandArea(params)
        console.log(res, '查看res是什么')
        this.tableData = res.data.landAreas
      },
      // 处理二维码回调，设置二维码的URL
      qrCodeCallback(url) {
        this.qrCodeUrl = url;
      },
      // 监听审批意见
      callApiOnBlur() {
        let identityObj = JSON.parse(localStorage.getItem("identityObj"));
        // console.log("identityObj", identityObj.content.identityId);
        this.identityId = identityObj.content.identityId;
        this.text = `${Url}?taskId=${this.taskId}/processInstanceId=${this.detailData}/agreeReason=${this.idea}/identityId=${this.identityId}`;
      },
      //显示以服务列表
      showDetail(row) {
        this.dialogVisible = true,
          console.log(row, '查看row是什么')
      },
      //关闭弹窗
      handleClose() {
        this.dialogVisible = false
      }
    },


  }
</script>

<style lang="less" scoped>
  .subsidyApplicationDetails {
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    .back {
      font-size: 22px;
    }

    .title {
      display: flex;
      justify-content: center;
    }

    .time {
      text-align: right;
      margin-bottom: 20px;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      /* 合并线段 */
      text-align: center;

      th,
      td {
        border: 1px solid black;
        padding: 8px;
        text-align: left;
        text-align: center;
      }
    }

    .introduce {
      box-sizing: border-box;
      padding: 0px;
      margin: 0px;
      height: 300px;
      width: 100%;
      border: solid 1px black;
      border-top: none;
      display: flex;

      .left {
        width: 50%;
        display: flex;
        padding-top: 20px;
        align-items: center;
        flex-direction: column;
        border-right: solid 1px black;

        .title {
          width: 90%;
          height: 60%;
        }

        .acceptor {
          width: 90%;
          height: 30%;
          display: flex;

          .img {
            margin-left: 20px;
            ;
          }
        }

        .time {
          width: 95%;
        }
      }

      .right {
        width: 50%;
        display: flex;
        padding-top: 20px;
        align-items: center;
        flex-direction: column;

        .examine {
          width: 90%;
          height: 60%;
        }

        .acceptor {
          width: 90%;
          height: 30%;
          display: flex;

          .img {
            margin-left: 20px;
            ;
          }
        }

        .time {
          width: 95%;
        }
      }
    }

    .ImgBox {
      width: 100%;
      display: flex;

      .title {
        display: flex;
        align-items: center;
      }

      .Left {
        width: 50%;
        display: flex;


      }

      .Right {
        width: 50%;
        display: flex;
      }
    }

    .ImgFiles {
      width: 100%;
      display: flex;

      .title {
        display: flex;
        align-items: center;
      }

      .filBox {
        margin-left: 50px;
      }
    }

    .introduce2 {
      box-sizing: border-box;
      padding: 0px;
      margin: 0px;
      height: 300px;
      width: 100%;
      border: solid 1px black;
      border-top: none;
      display: flex;

      .left {
        width: 50%;
        display: flex;
        padding-top: 20px;
        align-items: center;
        flex-direction: column;
        border-right: solid 1px black;

        .title {
          width: 90%;
          height: 30%;
        }

        .auditResult {
          height: 20%;
        }

        .acceptor {
          width: 90%;
          height: 30%;
          display: flex;

          .img {
            margin-left: 20px;
            ;
          }
        }

        .time {
          width: 95%;
        }
      }

      .right {
        width: 50%;
        display: flex;
        padding-top: 20px;
        align-items: center;
        flex-direction: column;
        border-right: solid 1px black;

        .title {
          width: 90%;
          height: 10%;
        }

        .auditResult {
          width: 90%;
          height: 30%;
        }

        .acceptor {
          width: 90%;
          height: 30%;
          display: flex;

          .img {
            margin-left: 20px;
            ;
          }
        }

        .time {
          width: 95%;
        }
      }
    }
  }
</style>
